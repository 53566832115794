import React, { useEffect, useState } from "react";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const ProductGallerySlider = ({ article }) => {
  const [imageUrls, setimageUrls] = useState([]);

  const openBigImage = (imageUrl) => {
    console.log(imageUrl);
  };

  useEffect(() => {
    setimageUrls(article.imageUrls || []);
  }, [article]);

  console.log(imageUrls);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <>
      <div className="slide-container">
        {imageUrls.length > 0 && (
          <Slide>
            {imageUrls.map((imageUrl) => (
              <div className="each-slide" key={imageUrl}>
                <div>
                  <Zoom>
                    <img
                      onClick={() => openBigImage(imageUrl)}
                      className="test-selector"
                      src={imageUrl}
                      alt=""
                    />
                  </Zoom>
                </div>
              </div>
            ))}
          </Slide>
        )}
      </div>
      {/* {imageUrls.length > 0 && (
        <SliderImage
          data={[
            ...imageUrls.map((img) => {
              return {
                image: img,
                text: "img",
              };
            }),
          ]}
          width="500px"
          showDescription={false}
          direction="right"
        />
      )} */}
      {/* <Slider {...settings} className="m-b5 primary btn-style-2" id="sync1">
        {imageUrls.map((imageUrl) => (
          <div key={imageUrl} className="item">
            <div className="mfp-gallery">
              <div className="dlab-box">
                <div className="dlab-thum-bx dlab-img-overlay1 ">
                  <img className="test-selector" src={imageUrl} alt="" />
                  <div className="overlay-bx">
                    <div className="overlay-icon">
                      <span
                        data-exthumbimage="images/product/item2/item1.jpg"
                        data-src="images/product/item2/item1.jpg"
                        className="check-km"
                        title="Image 1 Title will come here"
                      >
                        <i
                          className="ti-fullscreen"
                          onClick={() => openBigImage(imageUrl)}
                        ></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider> */}
    </>
  );
};

export default ProductGallerySlider;
