import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "../reference.css";

const HomeReference = ({ references }) => {
  return (
    <div className="section-full bg-gray content-inner">
      <div className="container">
        <div className="section-head text-center">
          <AnimationOnScroll
            offset={20}
            duration={0.4}
            animateIn="animate__fadeIn"
          >
            <h2 className="title"> Nos Références</h2>
          </AnimationOnScroll>
        </div>

        <div className="referenceList">
          {references &&
            references.map(
              (item) =>
                item.isFavorite === true && (
                  <div key={item._id} className="referenceItems">
                    <AnimationOnScroll
                      offset={20}
                      duration={0.4}
                      animateIn="animate__fadeIn"
                    >
                      <div className="dlab-box img-hvr-content-style-2">
                        <div className="dlab-media dlab-img-overlay1 dlab-img-effect img-container">
                          {" "}
                          <img
                            className="img-reference"
                            src={item.imageUrl}
                            alt=""
                          />
                          <div className="overlay-bx">
                            <div className="dlab-info-has-text">
                              <div className="text-white">
                                <p className="font-16 m-b5">{item.title}</p>
                                <div className="dlab-divider margin-0 bg-gray-dark"></div>
                                <p className="font-16 m-b5">
                                  <small>{item.description}</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </AnimationOnScroll>
                  </div>
                )
            )}
        </div>
      </div>
    </div>
  );
};

export default HomeReference;
