import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { url } from "../../utils/url";
import "./productSlider.css";

const ProductSlider = ({ subCategoryId }) => {
  // console.log("SubCategory: ", subCategoryId);

  const [articles, setArticles] = useState([]);
  console.log("ARTICLES RELATED:", articles);

  useEffect(() => {
    // find the subCategories
    let isApiSubscribed = true;
    if (subCategoryId) {
      fetchArticles(isApiSubscribed);
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [subCategoryId]);

  const fetchArticles = (isApiSubscribed) => {
    // find articles by the selectedSubCategory
    axios
      .get(`${url}/article/subCategory/${subCategoryId}`)
      .then((res) => {
        if (isApiSubscribed) {
          console.log(res.data);
          setArticles(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {articles.length > 3 ? (
        <Slider
          {...settings}
          className="img-carousel-content primary btn-style-1"
        >
          {articles.map((item) => (
            <div key={item._id} className="item">
              <div className="item-box">
                <Link to={`/products/details/${item._id}`}>
                  <div className="item-img">
                    <img
                      style={{
                        maxHeight: "180px",
                        objectFit: "cover",
                      }}
                      src={item.imageUrls[0]}
                      alt=""
                    />
                    <div className="item-info-in">
                      <p>Voir Plus</p>
                    </div>
                  </div>

                  <div className="item-info text-center text-black p-a10">
                    <h6 className="item-title text-uppercase font-weight-500">
                      <Link to={`/products/details/${item._id}`}>
                        {item.title}
                      </Link>
                    </h6>

                    <h4 className="item-price">
                      <span className="text-primary">
                        {item.tags[0].split(",")[0] || ""}{" "}
                      </span>
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <div className="slider-min-3">
          {articles.map((item) => (
            <div key={item._id} className="slider-min-3-item">
              <div className="item-box">
                <Link to={`/products/details/${item._id}`}>
                  <div className="item-img">
                    <img
                      style={{
                        maxHeight: "180px",
                        objectFit: "cover",
                        width: "100%",
                      }}
                      src={item.imageUrls[0]}
                      alt=""
                    />
                    <div className="item-info-in">
                      <p>Voir Plus</p>
                    </div>
                  </div>
                  <div className="item-info text-center text-black p-a10">
                    <h6 className="item-title text-uppercase font-weight-500">
                      <Link to={`/products/details/${item._id}`}>
                        {item.title}
                      </Link>
                    </h6>

                    <h4 className="item-price">
                      <span className="text-primary">
                        {item.tags[0].split(",")[0] || ""}{" "}
                      </span>
                    </h4>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default ProductSlider;
