import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "./category.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import axios from "axios";
import { url } from "../../utils/url";

const Category = () => {
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [articles, setArticles] = useState([]);

  const { categoryId } = useParams();

  useEffect(() => {
    // find the subCategories
    console.log(categoryId);
    let isApiSubscribed = true;
    fetchSubCategories(isApiSubscribed);
    // fetchArticles();

    return () => {
      // must read about the cleanup hooks
      // reference  https://blog.logrocket.com/understanding-react-useeffect-cleanup-function/#:~:text=What%20is%20the%20useEffect%20cleanup,itself%20using%20the%20cleanup%20function.
      isApiSubscribed = false;
    };
  }, [categoryId]);

  useEffect(() => {
    // find the subCategories
    let isApiSubscribed = true;
    if (selectedSubCategory?._id) {
      fetchArticles(isApiSubscribed);
    }

    return () => {
      isApiSubscribed = false;
    };
  }, [selectedSubCategory?._id]);

  const fetchSubCategories = (isApiSubscribed) => {
    // must be changed to fetch only the sub categories of one category
    axios
      .get(`${url}/sub-category/category/${categoryId}`)

      .then((res) => {
        if (isApiSubscribed) {
          // console.log("categories", res.data);
          setSubCategories(res.data);
          setSelectedSubCategory(res.data[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchArticles = (isApiSubscribed) => {
    // find articles by the selectedSubCategory
    axios
      .get(`${url}/article/subCategory/${selectedSubCategory?._id}`)
      .then((res) => {
        if (isApiSubscribed) {
          console.log(res.data);
          setArticles(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSelectedSubCategory = (subCat) => {
    setSelectedSubCategory(subCat);
    // fetch the articles related to this sub Category
  };

  return (
    <div className="page-content bg-white">
      <div className="section-full content-inner">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 m-b30">
              <aside className="side-bar shop-categories sticky-top">
                <div className="widget recent-posts-entry">
                  <div
                    className="dlab-accordion advanced-search toggle"
                    id="accordion1"
                  >
                    <h4>PRODUITS</h4>
                    <div className="sub-category-list">
                      {selectedSubCategory?._id &&
                        subCategories.length > 0 &&
                        subCategories.map((subCat) => (
                          <p
                            onClick={() => handleSelectedSubCategory(subCat)}
                            key={subCat._id}
                            className={
                              subCat._id === selectedSubCategory?._id
                                ? "active"
                                : ""
                            }
                          >
                            {subCat.title}
                          </p>
                        ))}
                    </div>
                    {/* <Dropdown
                      controlClassName="dropdown-category-list"
                      menuClassName="dropdown-category-list"
                      // arrowClassName="dropdown-category-list"
                      options={options}
                      onChange={() => {
                        console.log("CHANGE");
                      }}
                      value={defaultOption}
                    /> */}
                  </div>
                </div>
              </aside>
            </div>
            <div className="col-lg-9 col-md-8 m-b30">
              <div className="row">
                {articles &&
                  articles.map((article) => (
                    <div
                      key={article._id}
                      className="col-lg-4 col-md-6 col-sm-6"
                    >
                      <AnimationOnScroll
                        offset={20}
                        duration={0.4}
                        animateIn="animate__fadeIn"
                      >
                        <Link to={`/products/details/${article._id}`}>
                          <div className="item-box m-b10 product-itemmm">
                            <div className="item-img">
                              <img src={article.imageUrls[0]} alt="" />
                            </div>
                            <div className="item-info text-center text-black p-a10">
                              <h6 className="item-title font-weight-500">
                                {article.title}
                              </h6>

                              <h4 className="item-price">
                                <span className="text-primary">
                                  {article.tags[0].replaceAll(",", " - ")}
                                </span>
                              </h4>
                            </div>
                          </div>
                        </Link>
                      </AnimationOnScroll>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-full p-t50 p-b20 bg-primary text-white">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-lg-4">
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <div className="icon-bx-wraper left shop-service-info m-b30">
                  <div className="icon-md text-black radius">
                    <Link to="#" className="icon-cell text-white">
                      <i className="fa fa-trophy"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte">Expertise</h5>
                    <p>
                      Une expérience, une spécialisation et un savoir faire
                      poussées.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-4 col-lg-4">
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <div className="icon-bx-wraper left shop-service-info m-b30">
                  <div className="icon-md text-black radius">
                    <Link to="#" className="icon-cell text-white">
                      <i className="fa fa-handshake-o"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte">Exigence</h5>
                    <p>
                      Une sélection et un contrôle rigoureux lors de l'achat de
                      nos matières premières.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="col-md-4 col-lg-4">
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <div className="icon-bx-wraper left shop-service-info m-b30">
                  <div className="icon-md text-black radius">
                    <Link to="#" className="icon-cell text-white">
                      <i className="fa fa-shield"></i>
                    </Link>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte">Rapidité</h5>
                    <p>
                      Intégration des demandes clients dans des délais très
                      réduits.
                    </p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
