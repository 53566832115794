import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

import "./presentation.css";

const Presentaion = () => {
  return (
    <>
      <div className="page-content bg-white">
        <div className="content-block">
          <div className="section-full">
            <div className="row spno about-industry">
              <div
                className="col-lg-8 wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div className="dlab-post-media dlab-img-effect zoom">
                  <img
                    src={require("../../assets/slider/test1.jpg")}
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div
                className="col-lg-4 bg-white wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.4s"
              >
                <div className="service-box style2">
                  <div>
                    <AnimationOnScroll
                      offset={20}
                      duration={0.4}
                      animateIn="animate__fadeIn"
                    >
                      <h2 className="title text-black">
                        <span>Notre </span> <br />
                        Mission
                      </h2>
                      <p className="base-text-presentation-page">
                        Offrez des produits de qualité qui répondent aux besoins
                        et aux attentes de nos clients afin d'obtenir une
                        satisfaction globale des clients.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.6s"
              >
                <div className="dlab-post-media dlab-img-effect zoom">
                  <img
                    src={require("../../assets/slider/test.jpg")}
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
              <div
                className="col-lg-4 bg-secondry text-white wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.8s"
              >
                <div className="service-box style2">
                  <div>
                    <AnimationOnScroll
                      offset={20}
                      duration={0.4}
                      animateIn="animate__fadeIn"
                    >
                      <h2 className="title">
                        <span>NOS</span> <br />
                        VALEURS
                      </h2>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                      offset={20}
                      duration={0.4}
                      animateIn="animate__fadeIn"
                    >
                      <p className="base-text-presentation-page">
                        Disponibilité, écoute, réactivité, qualité et
                        compétitivité nous permettent à ce jour d'accompagner
                        nos clients fidèle à nos valeurs.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div className="dlab-post-media dlab-img-effect zoom">
                  <img
                    src={require("../../assets/slider/test3.jpg")}
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>

              <div
                className="col-lg-4 bg-white wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.2s"
              >
                <div className="service-box style2">
                  <div>
                    <AnimationOnScroll
                      offset={20}
                      duration={0.4}
                      animateIn="animate__fadeIn"
                    >
                      <h2 className="title text-black">
                        <span>Politique de</span> <br />
                        qualité
                      </h2>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                      offset={20}
                      duration={0.4}
                      animateIn="animate__fadeIn"
                    >
                      <p className="base-text-presentation-page">
                        Notre politique qualité à pour ultime objectif la
                        satisfaction et la réponse à la demande de nos clients
                        actuels et potentiels.
                      </p>
                    </AnimationOnScroll>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-8 wow fadeIn"
                data-wow-duration="2s"
                data-wow-delay="0.4s"
              >
                <div className="dlab-post-media dlab-img-effect zoom">
                  <img
                    src={require("../../assets/slider/test6.jpg")}
                    alt=""
                    className="img-cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Presentaion;
