import "./App.css";

import "./plugins/fontawesome/css/font-awesome.min.css";
import "./plugins/line-awesome/css/line-awesome.min.css";
import "./plugins/flaticon/flaticon.css";
import "./plugins/flaticon/beer/flaticon.css";

import "./css/plugins.css";
import "./css/style.css";
import "./css/skin/skin-1.css";
import "./css/templete.min.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/pages/home/Home";
import Presentaion from "./components/pages/Presentaion";
import Reference from "./components/pages/Reference";
import Contact from "./components/pages/Contact";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";

import ScrollToTop from "./components/shared/ScrollToTop";
import ScrollToTopRender from "./components/shared/ScrollToTopRender";
import Category from "./components/pages/products/Category";
import ProductDetails from "./components/pages/products/ProductDetails";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "./components/utils/url";

function App() {
  const [categories, setCategories] = useState([]);
  const [references, setReferences] = useState([]);

  useEffect(() => {
    axios
      .get(`${url}/category/all`)
      .then((result) => {
        console.log(result.data);
        setCategories(result.data);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${url}/reference/all`)
      .then((res) => {
        setReferences(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Router>
      <div className="skin-1">
        <ScrollToTopRender />
        <Header categories={categories} references={references} />
        <Routes>
          <Route
            path="/"
            element={<Home categories={categories} references={references} />}
          />
          <Route path="/presentation" element={<Presentaion />} />
          <Route
            path="/references"
            element={<Reference references={references} />}
          />
          <Route path="/contact" element={<Contact />} />

          {/* Category routes the last number is the id */}
          <Route path="/products/category/:categoryId" element={<Category />} />
          <Route
            path="/products/details/:productId"
            element={<ProductDetails />}
          />
        </Routes>
        <ScrollToTop className="scroltop style2 radius " />
        <Footer categories={categories} />
      </div>
    </Router>
  );
}

export default App;
