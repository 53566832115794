import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import "./projectSlider3.css";

const ProjectSlider3 = ({ categories }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  //  //creating the ref
  //  const customeSlider = React.creatRef();
  const customeSlider = useRef();

  // setting slider configurations

  const gotoNext = () => {
    customeSlider.current.slickNext();
  };

  const gotoPrev = () => {
    customeSlider.current.slickPrev();
  };

  return (
    <div className="home-slider-container">
      <Slider {...settings} className="project-carousel" ref={customeSlider}>
        {categories.map((category) => (
          <div
            key={category._id}
            className="category categorySlider wow fadeInUp"
            data-wow-duration="1s"
            data-wow-delay="0.1s"
            style={{ padding: " 0 !import " }}
          >
            <Link to={`/products/category/${category._id}`}>
              <div className="dlab-box portfolio-style1">
                <div className="slider-each-type">
                  <div className="slider-home-container">
                    <img
                      src={category.imageUrl}
                      alt="Snow"
                      style={{ width: "100%" }}
                    />

                    <div className="centered-div-slide-home-container">
                      <h2>
                        <i className={category.icon} aria-hidden="true"></i>
                        <p>{category.title}</p>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </Slider>

      <i
        className="fa fa-chevron-circle-left prev"
        onClick={gotoPrev}
        aria-hidden="true"
      ></i>

      <i
        className="fa fa-chevron-circle-right next"
        onClick={gotoNext}
        aria-hidden="true"
      ></i>
    </div>
  );
};

export default ProjectSlider3;
