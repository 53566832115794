import React, { useRef, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { footerData } from "../data";
import emailjs from "@emailjs/browser";

const bg = require("../../assets/homeProduct/test1.jpg");

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // validate the data
    emailjs
      .sendForm(
        "service_6rbn9wc",
        "cad_template",
        e.target,
        "ngYGGxYmFJmTZK7Fo"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsLoading(false);
          setEmail("");
          setName("");
          setPhone("");
          setMessage("");
        },
        (error) => {
          setIsLoading(false);
          console.log(error.text);
        }
      );
  };

  return (
    <div className="page-content bg-white">
      <div
        className="section-full content-inner contact-page-9 overlay-black-dark"
        style={{ background: "url(" + bg + ") 30%" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 text-white">
              <div className="row">
                <div className="col-lg-12 col-md-12 m-b30">
                  <AnimationOnScroll
                    offset={20}
                    duration={0.4}
                    animateIn="animate__fadeIn"
                  >
                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                      <div className="icon-content">
                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary">
                            <i className="ti-location-pin"></i>
                          </span>
                          Adresse
                        </h5>
                        <p className="footer-text-size">{footerData.address}</p>

                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary">
                            <i className="ti-alarm-clock"></i>
                          </span>
                          Heures de travail
                        </h5>
                        <p className="m-b0 footer-text-size">
                          {footerData.workerDay1}
                        </p>
                        <p className="footer-text-size">
                          {footerData.workerDay2}
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-12 col-md-6 m-b30">
                  <AnimationOnScroll
                    offset={20}
                    duration={0.4}
                    animateIn="animate__fadeIn"
                  >
                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                      <div className="icon-content">
                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary">
                            <i className="ti-email"></i>
                          </span>
                          E-mail
                        </h5>
                        <p className="m-b0 footer-text-size">
                          {footerData.email1}
                        </p>
                        <p className="m-b0 footer-text-size">
                          {footerData.email2}
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="col-lg-12 col-md-6 m-b30">
                  <AnimationOnScroll
                    offset={20}
                    duration={0.4}
                    animateIn="animate__fadeIn"
                  >
                    <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm">
                      <div className="icon-content">
                        <h5 className="dlab-tilte">
                          <span className="icon-sm text-primary">
                            <i className="ti-mobile"></i>
                          </span>
                          Numéros de téléphone
                        </h5>
                        <p className="m-b0 footer-text-size">
                          {footerData.phone1}
                        </p>
                        <p className="m-b0 footer-text-size">
                          {footerData.phone3}
                        </p>
                        <p className="m-b0 footer-text-size">
                          {footerData.phone4}
                        </p>
                        <p className="m-b0 footer-text-size">
                          {footerData.phone2}
                        </p>
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 m-b30">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="inquiry-form wow box-shadow bg-white fadeInUp"
                data-wow-delay="0.2s"
              >
                <AnimationOnScroll
                  offset={20}
                  duration={0.4}
                  animateIn="animate__fadeIn"
                >
                  <h3 className="title-box font-weight-300 m-t0 m-b10">
                    Envoyez-nous votre message{" "}
                    <span className="bg-primary"></span>
                  </h3>
                  <p className="base-text-web">
                    Contactez nous pour tous renseignements.
                  </p>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-user text-primary"></i>
                          </span>
                          <input
                            name="name"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Votre Nom"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-mobile text-primary"></i>
                          </span>
                          <input
                            name="phone"
                            type="text"
                            required
                            className="form-control"
                            placeholder="Votre Téléphone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-email text-primary"></i>
                          </span>
                          <input
                            name="email"
                            type="email"
                            className="form-control"
                            required
                            placeholder="Votre Adresse Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <div className="input-group">
                          <span className="input-group-addon">
                            <i className="ti-agenda text-primary"></i>
                          </span>
                          <textarea
                            name="message"
                            rows="10"
                            className="form-control"
                            required
                            placeholder="Votre Message..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12">
                      {isLoading ? (
                        <p style={{ fontSize: "1.2rem", textAlign: "center" }}>
                          Message envoyer avec success
                        </p>
                      ) : (
                        <button
                          name="submit"
                          type="submit"
                          value="Submit"
                          className="site-button button-md"
                          disabled={isLoading}
                        >
                          <span>Envoyez</span>
                        </button>
                      )}
                      {/* <button
                        name="submit"
                        type="submit"
                        value="Submit"
                        className="site-button button-md"
                        disabled={isLoading}
                      >
                        <span>Envoyez</span>
                      </button> */}
                    </div>
                  </div>
                </AnimationOnScroll>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
