import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { animateScroll } from "react-scroll";

export default function ScrollToTopRender() {
  const { pathname } = useLocation();

  useEffect(() => {
    // window.scrollTo(0, 0);
    animateScroll.scrollToTop();
  }, [pathname]);

  return null;
}
