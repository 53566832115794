import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductGallerySlider from "./ProductGallerySlider";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ProductSlider from "./ProductSlider";
import axios from "axios";
import { url } from "../../utils/url";

const ProductDetails = () => {
  const [article, setArticle] = useState({});

  const { productId } = useParams();

  useEffect(() => {
    console.log("New Product", productId);
    fetchArticle();
  }, [productId]);

  const fetchArticle = () => {
    // must be changed to fetch only the sub categories of one category
    axios
      .get(`${url}/article/${productId}`)
      .then((res) => {
        console.log(res.data);
        setArticle(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="section-full content-inner bg-white">
      <div className="container woo-entry">
        <div className="row m-b30">
          <div className="col-md-5 col-lg-5 col-sm-12 m-b30">
            <ProductGallerySlider article={article} />
          </div>
          <div className="col-md-7 col-lg-7 col-sm-12">
            <form method="post" className="cart sticky-top">
              <div className="dlab-post-title">
                <h4 className="post-title">
                  <Link to="#">{article?.title}</Link>
                </h4>
                <p className="m-b10">{article?.description1}</p>
                <div className="dlab-divider bg-gray tb15">
                  <i className="icon-dot c-square"></i>
                </div>
              </div>

              <div className="shop-item-tage">
                <span>Tags :- </span>
                <Link to="#">
                  {" "}
                  {article.tags && article.tags[0].replaceAll(",", " - ")}{" "}
                </Link>
              </div>
              <div className="dlab-divider bg-gray tb15">
                <i className="icon-dot c-square"></i>
              </div>
              <div className="row">
                <div className="m-b30 col-md-5 col-sm-4">
                  <h6>Select quantity</h6>
                  <div className="quantity btn-quantity style-1">
                    <input
                      id="demo_vertical2"
                      type="number"
                      className="form-control"
                      name="demo_vertical2"
                      defaultValue={0}
                    />
                  </div>
                </div>
              </div>
              <div className="m-b30">
                <h6>Couleurs Disponibles</h6>
                <div
                  className="btn-group product-item-color"
                  data-toggle="buttons"
                >
                  <label className="btn bg-black active">
                    <input type="radio" name="options" id="option6" />
                  </label>
                  <label className="btn bg-white">
                    <input type="radio" name="options" id="option7" />
                  </label>
                  <div className="autres">{/* <p>+Autres</p> */}</div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="row m-b30">
          <div className="col-lg-12">
            <div className="dlab-tabs  product-description tabs-site-button">
              <Tabs>
                <TabList className="nav nav-tabs description-list-buttons">
                  <Tab selected className="tab-active">
                    <button className="site-button">
                      <i className="fa fa-photo"></i> Additional Information
                    </button>
                  </Tab>
                  <Tab selected className="tab-active description-buttons">
                    <button className="site-button">
                      <i className="fa fa-globe"></i> Description
                    </button>
                  </Tab>

                  <Tab selected className="tab-active">
                    <button className="site-button">
                      <i className="fa fa-cog"></i> Demander un Devis
                    </button>
                  </Tab>
                </TabList>

                <TabPanel className="tab-pane ">
                  <table className="table table-bordered">
                    <tbody>
                      {article &&
                        article.additionalInformations &&
                        article.additionalInformations.map((art) => (
                          <tr key={art.key}>
                            <td>{art.key}</td>
                            <td>{art.value}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </TabPanel>

                <TabPanel className="tab-pane">
                  <ul className="list-check primary">
                    {article.description2 &&
                      article.description2
                        .split(".")
                        .splice(0, article.description2.split(".").length - 1)
                        .map((desc) => <li key={desc}>{desc}.</li>)}
                  </ul>
                </TabPanel>

                <TabPanel className="tab-pane ">
                  <div id="review_form_wrapper">
                    <form
                      className="inquiry-form wow box-shadow bg-white fadeInUp"
                      data-wow-delay="0.2s"
                    >
                      <h3 className="title-box font-weight-300 m-t0 m-b10">
                        Demander un Devis
                        <span className="bg-primary"></span>
                      </h3>

                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Votre Nom"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Votre Téléphone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Votre Adresse Email"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="10"
                                className="form-control"
                                required
                                placeholder="Votre Message..."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-md"
                          >
                            {" "}
                            <span>Envoyez</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h5 className="m-b20">Produits Similaires</h5>
            <ProductSlider subCategoryId={article.subCategory} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
