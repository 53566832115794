import React from "react";

import HomeAbout from "./HomeAbout";
import HomeReference from "./HomeReference";
import {
  referenceData,
  homeAbout,
  chooseUsData,
  historyData,
} from "../../data";

import HomeChooseUs from "./HomeChooseUs";
import ProjectSlider3 from "./ProjectSlider3";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Home = ({ categories, references }) => {
  return (
    <>
      <ProjectSlider3 categories={categories} />

      <AnimationOnScroll offset={20} duration={0.4} animateIn="animate__fadeIn">
        <HomeAbout homeAbout={homeAbout} />
      </AnimationOnScroll>
      <HomeReference references={references} />
      <HomeChooseUs chooseUsData={chooseUsData} historyData={historyData} />
    </>
  );
};

export default Home;
