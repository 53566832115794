/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Link } from "react-router-dom";
import { footerData, navbarData, socialLinks } from "../data";

const Footer = ({ categories }) => {
  return (
    <>
      <footer className="site-footer style1">
        <div
          style={{ background: "#500300 " }}
          className="section-full p-t50 p-b20 text-white footer-info-bar"
        >
          <div className="container">
            <AnimationOnScroll
              offset={20}
              duration={0.4}
              animateIn="animate__fadeIn"
            >
              <div className="row">
                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                  <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                    <div className="icon-content">
                      <h5 className="dlab-tilte">
                        <span className="icon-sm">
                          <i className="ti-location-pin"></i>
                        </span>
                        Adresse
                      </h5>
                      <p className="op7 footer-text-size">
                        {footerData.address}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                  <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                    <div className="icon-content">
                      <h5 className="dlab-tilte">
                        <span className="icon-sm">
                          <i className="ti-email"></i>
                        </span>
                        E-mail
                      </h5>
                      <p className="m-b0 op7 footer-text-size">
                        {footerData.email1}
                      </p>
                      <br />
                      <p className="op7 footer-text-size">
                        {footerData.email2}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                  <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                    <div className="icon-content">
                      <h5 className="dlab-tilte">
                        <span className="icon-sm">
                          <i className="ti-mobile"></i>
                        </span>
                        Numéros de téléphone
                      </h5>
                      <p className="m-b0 op7 footer-text-size">
                        {footerData.phone1}
                      </p>
                      <br />
                      <p className="op7 footer-text-size">
                        {footerData.phone2}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 m-b30">
                  <div className="icon-bx-wraper bx-style-1 p-a20 radius-sm br-col-w1">
                    <div className="icon-content">
                      <h5 className="dlab-tilte">
                        <span className="icon-sm">
                          <i className="ti-alarm-clock"></i>
                        </span>
                        Heures de travail
                      </h5>
                      <p className="m-b0 op7 footer-text-size ">
                        {footerData.workerDay1}
                      </p>
                      <p className="op7 footer-text-size">
                        {footerData.workerDay2}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        <div className="footer-top">
          <div className="container">
            <AnimationOnScroll
              offset={20}
              duration={0.4}
              animateIn="animate__fadeIn"
            >
              <div className="row">
                <div className="col-md-4 col-sm-6">
                  <div className="widget widget_about">
                    <h4 className="footer-title">A Propos De L'entreprise</h4>
                    <p className="footer-text-size">{footerData.description}</p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-6">
                  <div className="widget">
                    <h4 className="footer-title">Liens Utiles</h4>
                    <ul className="list-2">
                      {categories.map((category) => (
                        <li key={category._id}>
                          <Link
                            className="footer-text-size"
                            to={`/products/category/${category._id}`}
                          >
                            {category.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="widget widget_subscribe">
                    <h4 className="footer-title">Localisation</h4>

                    <iframe
                      title="qsdfqdsf"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.53319686519!2d10.696499351109619!3d34.74215908032774!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13002b52a634c93f%3A0x49306871a45f9739!2sCAD%20Cintre%20%26%20accessoire%20divers!5e0!3m2!1sen!2stn!4v1643378261595!5m2!1sen!2stn"
                      width="240"
                      height="200"
                      style={{ border: 0 }}
                      loading="lazy"
                    ></iframe>
                  </div>
                </div>
              </div>
            </AnimationOnScroll>
          </div>
        </div>

        <div className="footer-bottom footer-line">
          <div className="container">
            <div className="footer-bottom-in">
              <div className="footer-bottom-logo">
                <Link style={{ background: "#fff" }} to={"/"}>
                  <img src={require("../../assets/logo/cadLogo1.png")} alt="" />
                </Link>
              </div>
              <div className="footer-bottom-social">
                <ul className="dlab-social-icon dez-border">
                  {socialLinks.map((item) => (
                    <li key={item.id}>
                      <a
                        className={item.footerClassName}
                        target="_blank"
                        href={item.path}
                        rel="noreferrer"
                      ></a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom footer-line">
          <div className="container">
            <p
              style={{
                margin: 0,
                paddingBottom: "1rem",
                paddingTop: "1rem",
                fontSize: "1rem",
                textAlign: "center",
              }}
            >
              © Conçu par: Mohamed Mahdi Bouaziz
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
