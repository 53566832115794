import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Counter from "./Counter";

const bg2 = require("../../../assets/slider/test2.jpg");

const HomeChooseUs = ({ chooseUsData, historyData }) => {
  return (
    <div
      className="section-full content-inner-1 overlay-black-dark about-8-service bg-img-fix"
      style={{ backgroundImage: "url(" + bg2 + ")" }}
    >
      <div className="container">
        <div className="section-head text-white text-center">
          <AnimationOnScroll
            offset={20}
            duration={0.4}
            animateIn="animate__fadeIn"
          >
            <h2 className="title-box m-tb0 max-w800 m-auto">
              Pourquoi choisir Cintres & Accessoires Divers ?{" "}
              <span className="bg-primary"></span>
            </h2>
          </AnimationOnScroll>
          <AnimationOnScroll
            offset={20}
            duration={0.4}
            animateIn="animate__fadeIn"
          >
            <p className="base-text-web">
              Pour plus de 20 ans, notre société œuvre autour de 3 valeurs.
              Elles constituent le pilier de notre relation avec nos clients.
            </p>
          </AnimationOnScroll>
        </div>
      </div>
      <div className="container">
        <div className="row text-white">
          {chooseUsData.map((item) => (
            <div key={item.id} className="col-lg-4 col-md-4 m-b30">
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <div className="icon-bx-wraper bx-style-1 p-a30 center">
                  <div className="icon-lg text-white m-b20">
                    <i className={item.iconClassName}></i>
                  </div>
                  <div className="icon-content">
                    <h5 className="dlab-tilte text-uppercase">{item.title}</h5>
                    <p className="base-text-web">{item.description}</p>
                  </div>
                </div>
              </AnimationOnScroll>
            </div>
          ))}
        </div>
      </div>
      <div className="choses-info text-white">
        <div className="container-fluid">
          <div className="row choses-info-content">
            {historyData.map((item) => (
              <div
                key={item.id}
                className="col-lg-3 col-md-6 col-sm-6 col-6 p-a30 wow zoomIn"
                data-wow-delay="0.2s"
              >
                <h2 className="m-t0 m-b10 font-weight-400 font-45">
                  <i className="flaticon-alarm-clock m-r10"></i>
                  <span className="counter">
                    <Counter count={item.number} />
                  </span>
                  +
                </h2>
                <h4 className="font-weight-300 m-t0">{item.title}</h4>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeChooseUs;
