// navbar data
export const navbarData = [
  {
    id: 1,
    name: "Accueil",
    path: "/",
    hasChild: false,
  },
  {
    id: 2,
    name: "Présentation",
    path: "/presentation",
    hasChild: false,
  },
  {
    id: 3,
    name: "Produits",
    path: "#",
    hasChild: true,
    child: [
      {
        id: "aa",
        path: "/products/category/1",
        name: "Cintres",
      },
      {
        id: "bb",
        path: "/products/category/2",
        name: "Accessoire",
      },
      {
        id: "cc",
        path: "/products/category/3",
        name: "Sacs et housses de vetements",
      },
    ],
  },
  {
    id: 4,
    name: "Nos Références",
    path: "/references",
    hasChild: false,
  },
  {
    id: 5,
    name: "Contactez-Nous",
    path: "/contact",
    hasChild: false,
  },
];

export const socialLinks = [
  {
    id: 1,
    navClassName: "site-button circle fa fa-facebook",
    footerClassName: "fa fa-facebook footer-icons",
    path: "https://www.facebook.com/Cintres-Accessoires-Divers-CAD-188297557954037",
  },
  {
    id: 2,
    navClassName: "site-button circle fa fa-instagram",
    footerClassName: "fa fa-instagram footer-icons",
    path: "https://www.facebook.com/Cintres-Accessoires-Divers-CAD-188297557954037",
  },
];

//footer data
export const footerData = {
  address: "Rte aéroport Z.I oued  chaabouni 3071 sfax tunsie BP 173 ",
  email1: "contact@cad-cintre.com.tn",
  email2: "cad@tunet.tn",
  phone1: "Tel: 74 682 706",
  phone2: "Fax: 74 682 709",
  phone3: "Tel: 74 682 708",
  phone4: "Tel: 26 801 077",
  workerDay1: "Du Lundi Au Vendredi - 08.00-17.00",
  workerDay2: "Samedi - 08.00-14.00",
  description:
    "Notre société, leader sur le marché, s'est spécialisée dans la production de cintres et des accessoires pouvant satisfaire toutes les exigences de l'industrie de l'habillement.",
};

// Accueil page data

export const homeSliderV2Data = [
  {
    id: 1,
    image: require("../assets/slider/test2.jpg"),
    title: "Cintres",
    icon: "fa fa-cube",
    path: "/products/category/1",
  },
  {
    id: 2,
    image: require("../assets/slider/test4.jpg"),
    title: "Accessoires",
    icon: "fa fa-cube",
    path: "/products/category/1",
  },
  {
    id: 3,
    image: require("../assets/slider/test1.jpg"),
    title: "Sacs & Housses",
    icon: "fa fa-cube",
    path: "/products/category/1",
  },
  {
    id: 4,
    image: require("../assets/slider/test4.jpg"),
    title: "Produit",
    icon: "fa fa-cube",
    path: "/products/category/1",
  },
];

export const homeSliderContent = [
  {
    image: require("../assets/slider/test2.jpg"),
    title: "Personnalisez votre cintre",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require("../assets/slider/test4.jpg"),
    title: "Housse costume avec impression personnalisée",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    image: require("../assets/slider/test1.jpg"),
    title: "Boîte de rangement pliable non-tissé",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];

export const homeAbout = {
  image: require("../assets/homeAbout/test5.jpg"),
  shortTitle: "À propos de",
  title: "Cintres & Accessoires Divers - CAD",
  subTitle: "Nous faisons notre mieux pour vous satisfaire.",
  description:
    "Notre société, leader sur le marché, s'est spécialisée dans la production de cintres et des accessoires pouvant satisfaire toutes les exigences de l'industrie de l'habillement.",
};

export const poductContent = [
  {
    id: 1,
    image: require("../assets/homeProduct/test2.jpg"),
    productName: "Cintres",
  },
  {
    id: 2,
    image: require("../assets/homeProduct/test1.jpg"),
    productName: "Accessoires",
  },
  {
    id: 3,
    image: require("../assets/homeProduct/test6.jpg"),
    productName: "Sacs et Housses de Vetements",
  },
  {
    id: 4,
    image: require("../assets/homeProduct/test1.jpg"),
    productName: "Produit3",
  },
  {
    id: 5,
    image: require("../assets/homeProduct/test4.jpg"),
    productName: "Produit4",
  },
];

export const referenceData = [
  {
    id: 1,
    image: require("../assets/referenceLogo/haLogo.jpg"),
    title: "HA",
    description: "Description",
  },
  {
    id: 2,
    image: require("../assets/referenceLogo/existLogo.png"),
    title: "Exist",
    description: "Description",
  },
  {
    id: 3,
    image: require("../assets/referenceLogo/daineseLogo.jpg"),
    title: "Dainese",
    description: "Description",
  },
];

export const chooseUsData = [
  {
    id: 1,
    iconClassName: "fa fa-trophy",
    title: "Expertise",
    description:
      "Une expérience, une spécialisation et un savoir faire poussées.",
  },
  {
    id: 2,
    iconClassName: "fa fa-handshake-o",

    title: "Exigence",
    description:
      "Une sélection et un contrôle rigoureux lors de l'achat de nos matières premières.",
  },
  {
    id: 3,
    iconClassName: "fa fa-shield",
    title: "Rapidité",
    description:
      "Intégration des demandes clients dans des délais très réduits.",
  },
];

export const historyData = [
  {
    id: 1,
    title: "Années d'Experience",
    number: 23,
  },
  {
    id: 2,
    title: "Produits",
    number: 120,
  },
  {
    id: 3,
    title: "Machines",
    number: 30,
  },
  {
    id: 4,
    title: "Clients Satisfaits",
    number: 300,
  },
];
