/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import Sticky from "react-stickynode";
import { navbarData, socialLinks } from "../data";

const Header = ({ categories, references }) => {
  useEffect(() => {
    // sidebar open/close
    // const btn = document.querySelector(".navicon");
    // const nav = document.querySelector(".header-nav");
    // const toggleFunc = () => {
    //   btn.classList.toggle("open");
    //   nav.classList.toggle("show");
    // };
    // btn.addEventListener("click", toggleFunc);
    // Sidenav li open close
    if (categories.length > 0 && references.length > 0) {
      var navUl = [].slice.call(
        document.querySelectorAll(".header-nav > ul > li")
      );
      for (var y = 0; y < navUl.length; y++) {
        navUl[y].addEventListener("click", function () {
          console.log("clicked");
          checkLi(this);
        });
      }
      function checkLi(current) {
        const active = current.classList.contains("open");
        navUl.forEach((el) => el.classList.remove("open"));
        //current.classList.add('open');
        if (active) {
          current.classList.remove("open");
        } else {
          current.classList.add("open");
        }
      }
    }
  });

  const closeNavIfOpen = () => {
    const btn = document.querySelector(".navicon");
    const nav = document.querySelector(".header-nav");
    const toggleFunc = () => {
      btn.classList.toggle("open");
      nav.classList.toggle("show");
    };
    toggleFunc();
  };

  return (
    <>
      <header className="site-header mo-left header">
        <Sticky innerZ={999} enabled={true}>
          <div className="sticky-header main-bar-wraper navbar-expand-lg">
            <div className="main-bar clearfix ">
              <div className="container clearfix">
                <div className="logo-header mostion">
                  <NavLink to={"/"}>
                    <img
                      src={require("../../assets/logo/cadLogo1.png")}
                      alt=""
                    />
                  </NavLink>
                </div>

                <button
                  className="navbar-toggler navicon justify-content-end"
                  onClick={(e) => {
                    const btn = document.querySelector(".navicon");
                    const nav = document.querySelector(".header-nav");
                    btn.classList.toggle("open");
                    nav.classList.toggle("show");
                  }}
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </button>

                <div
                  className={`header-nav navbar-collapse collapse justify-content-end`}
                  id="navbarNavDropdown"
                >
                  <div className="logo-header d-md-block d-lg-none">
                    <NavLink to={"/"}>
                      <img
                        src={require("../../assets/logo/cadLogo1.png")}
                        alt=""
                      />
                    </NavLink>
                  </div>

                  <ul className=" nav navbar-nav">
                    {navbarData.map((item) => (
                      <li
                        key={item.id}
                        onClick={(e) => {
                          console.log("test");
                        }}
                      >
                        <NavLink
                          className={({ isActive }) => {
                            if (item.hasChild) {
                              if (
                                window.location.href.indexOf("#") > -1 ||
                                window.location.pathname.indexOf("category") ===
                                  -1
                              ) {
                                return undefined;
                              }
                              return "selected";
                            }
                            return isActive ? "selected" : undefined;
                          }}
                          onClick={() =>
                            item.hasChild ? null : closeNavIfOpen()
                          }
                          to={item.path}
                        >
                          {item.name}
                          {item.hasChild && (
                            <i className="fa fa-chevron-down"></i>
                          )}
                        </NavLink>
                        {item.hasChild && (
                          <ul className="sub-menu">
                            {categories.map((category) => (
                              <li key={category._id}>
                                <NavLink
                                  onClick={closeNavIfOpen}
                                  to={`/products/category/${category._id}`}
                                >
                                  {category.title}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    ))}
                  </ul>

                  <div className="dlab-social-icon">
                    <ul>
                      {socialLinks.map((item) => (
                        <li key={item.id}>
                          <a
                            className={item.navClassName}
                            target="_blank"
                            href={item.path}
                            rel="noreferrer"
                          ></a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
      </header>
    </>
  );
};

export default Header;
