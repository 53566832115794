import React from "react";
import { Link } from "react-router-dom";

const HomeAbout = ({ homeAbout }) => {
  return (
    <>
      <div className="container" style={{ marginTop: "1.5rem" }}>
        <div className="on-show-slider">
          <div className="item">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 m-b30">
                <div className="our-story">
                  <span>{homeAbout.shortTitle}</span>
                  <h2 className="title">{homeAbout.title}</h2>
                  <h4 className="title">{homeAbout.subTitle}</h4>
                  <p className="base-text-web">{homeAbout.description}</p>
                  <Link to="/presentation" className="site-button">
                    Voire Plus
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 m-b30">
                <img src={homeAbout.image} className="radius-sm" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeAbout;
