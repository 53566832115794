import axios from "axios";
import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "./reference.css";
import { url } from "../utils/url";

const references = [
  {
    id: 1,
    refCategory: "Category1",
    title: "Hamadi Abid",
    description: "Description sghira ala hamadi abid",
    imgUrl: require("../../assets/referenceLogo/haLogo.jpg"),
  },
  {
    id: 2,
    refCategory: "Category2",
    title: "Hamadi Abid",
    description: "Description sghira ala hamadi abid",
    imgUrl: require("../../assets/referenceLogo/daineseLogo.jpg"),
  },
  {
    id: 3,
    refCategory: "Category1",
    title: "Hamadi Abid",
    description: "Description sghira ala hamadi abid",
    imgUrl: require("../../assets/referenceLogo/haLogo.jpg"),
  },
];

const Reference = ({ references }) => {
  return (
    <div className="page-content bg-white">
      <div col="col-md-4">
        <div className="content-block">
          <div
            className="section-full content-inner-2 portfolio text-uppercase bg-gray"
            id="portfolio"
          >
            <div className="container">
              {/* Reference1 */}
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <h2 className="title text-black reference-title">
                  Societe de confection
                </h2>
              </AnimationOnScroll>
              <div className="referenceList">
                {references.map(
                  (ref) =>
                    ref.type === "SOCIETE DE CONFECTION" && (
                      <div key={ref._id} className="referenceItems">
                        <AnimationOnScroll
                          offset={20}
                          duration={0.4}
                          animateIn="animate__fadeIn"
                        >
                          <div className="dlab-box img-hvr-content-style-2 ">
                            <div className="dlab-media dlab-img-overlay1 dlab-img-effect img-container">
                              {" "}
                              <img
                                src={ref.imageUrl}
                                className="img-reference"
                                alt=""
                              />
                              <div className="overlay-bx">
                                <div className="dlab-info-has-text">
                                  <div className="text-white">
                                    <p className="font-16 m-b5">{ref.title}</p>
                                    <div className="dlab-divider margin-0 bg-gray-dark"></div>
                                    <p className="font-16 m-b5">
                                      <small>{ref.description}</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AnimationOnScroll>
                      </div>
                    )
                )}
              </div>

              {/* Reference2 */}
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <h2 className="title text-black reference-title">
                  Prêt à porter
                </h2>
              </AnimationOnScroll>
              <div className="referenceList">
                {references.map(
                  (ref) =>
                    ref.type === "PRÊT À PORTER" && (
                      <div key={ref._id} className="referenceItems">
                        <AnimationOnScroll
                          offset={20}
                          duration={0.4}
                          animateIn="animate__fadeIn"
                        >
                          <div className="dlab-box img-hvr-content-style-2">
                            <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                              {" "}
                              <img src={ref.imageUrl} alt="" />
                              <div className="overlay-bx">
                                <div className="dlab-info-has-text">
                                  <div className="text-white">
                                    <p className="font-16 m-b5">{ref.title}</p>
                                    <div className="dlab-divider margin-0 bg-gray-dark"></div>
                                    <p className="font-16 m-b5">
                                      <small>{ref.description}</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AnimationOnScroll>
                      </div>
                    )
                )}
              </div>

              {/* Reference3 */}
              <AnimationOnScroll
                offset={20}
                duration={0.4}
                animateIn="animate__fadeIn"
              >
                <h2 className="title text-black reference-title">
                  Pressing et Laverie
                </h2>
              </AnimationOnScroll>
              <div className="referenceList">
                {references.map(
                  (ref) =>
                    ref.type === "PRESSING ET LAVERIE" && (
                      <div key={ref._id} className="referenceItems">
                        <AnimationOnScroll
                          offset={20}
                          duration={0.4}
                          animateIn="animate__fadeIn"
                        >
                          <div className="dlab-box img-hvr-content-style-2">
                            <div className="dlab-media dlab-img-overlay1 dlab-img-effect">
                              {" "}
                              <img src={ref.imageUrl} alt="" />
                              <div className="overlay-bx">
                                <div className="dlab-info-has-text">
                                  <div className="text-white">
                                    <p className="font-16 m-b5">{ref.title}</p>
                                    <div className="dlab-divider margin-0 bg-gray-dark"></div>
                                    <p className="font-16 m-b5">
                                      <small>{ref.description}</small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </AnimationOnScroll>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reference;
